<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Grade
      </div>
      <div class="text-500 mb-5">Agenda Complementares</div>

      <div class="grid">
        <div class="col-6">
          <div class="card">
            <div
              class="
                flex flex-column
                mb-4
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                <div class="text-500 mb-5">Data do Período</div>
              </div>
              <div class="mt-2 ml-auto md:mt-0">
                <Button
                  label="Novo"
                  icon="pi pi-plus"
                  class="p-button p-button-text mr-2"
                  @click="$router.push({ name: 'novoPeriodo' })"
                />
              </div>
            </div>

            <Toast />

            <DataTable
              dataKey="id"
              :value="periodos"
              :row-hover="true"
              responsiveLayout="stack"
              breakpoint="640px"
              @rowClick="onRowClickPeriodo"
              selectionMode="single"
            >
              <template #empty> Nenhum registro encontrado. </template>

              <template #loading> Carregando registros. Aguarde ... </template>

              <Column>
                <template #body="{ data }">
                  {{ formatDate(data.dataInicial) }} até
                  {{ formatDate(data.dataFinal) }}
                </template>
              </Column>

              <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-trash"
                    class="p-button-text p-button mt-2"
                    @click="
                      confirmDeleteRecord({
                        list: 'periodo',
                        ...slotProps.data,
                      })
                    "
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div
              class="
                flex flex-column
                mb-4
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                <div class="text-500 mb-5">Data Exceção</div>
              </div>
              <div class="mt-2 ml-auto md:mt-0">
                <Button
                  label="Novo"
                  icon="pi pi-plus"
                  class="p-button p-button-text mr-2"
                  @click="$router.push({ name: 'novaExcecao' })"
                />
              </div>
            </div>

            <Toast />

            <DataTable
              dataKey="id"
              :value="excecoes"
              :row-hover="true"
              responsiveLayout="stack"
              breakpoint="640px"
              @rowClick="onRowClickExcecao"
              selectionMode="single"
            >
              <template #empty> Nenhum registro encontrado. </template>

              <template #loading> Carregando registros. Aguarde ... </template>

              <Column field="data">
                <template #body="{ data }">
                  {{ formatDate(data.data) }}
                  {{ data.resumo ? (' - ' + data.resumo) : '' }}
                </template>
              </Column>

              <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-trash"
                    class="p-button-text p-button mt-2"
                    @click="confirmDeleteRecord({
                        list: 'excecao',
                        ...slotProps.data,
                      })"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        v-model:visible="deleteRecordDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
      >
        <div class="flex align-items-center justify-content-center">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="record">Confirma exclusão?</span>
        </div>
        <template #footer>
          <Button
            label="Cancelar"
            icon="pi pi-times"
            class="p-button-text"
            @click="deleteRecordDialog = false"
          />
          <Button
            label="Excluir"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger"
            @click="deleteRecord"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import AreaMedicaService from "../../services/AreaMedicaService";

export default {
  data() {
    return {
      periodos: [],
      excecoes: [],
      deleteRecordDialog: false,
      record: null,
    };
  },
  mounted() {
    this.$service = new AreaMedicaService(
      `/agendas/${this.$route.params.id}/periodos`
    );
    this.$serviceExcecoes = new AreaMedicaService(
      `/agendas/${this.$route.params.id}/excecoes`
    );
    this.load();
  },
  methods: {
    async load() {
      this.$service.findAll({}).then(({ data }) => (this.periodos = data));
      this.$serviceExcecoes.findAll({}).then(({ data }) => (this.excecoes = data));
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString("pt-br", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    onRowClickPeriodo({ data }) {
      this.$router.push({
        name: "editarPeriodo",
        params: { idPeriodo: data.id },
      });
    },
    onRowClickExcecao({ data }) {
      this.$router.push({
        name: "editarExcecao",
        params: { idExcecao: data.id },
      });
    },
    confirmDeleteRecord(record) {
      this.record = record;
      this.deleteRecordDialog = true;
    },
    async deleteRecord() {
      try {
        if (this.record.list === "periodo") {
          await this.$service.remove(this.record.id);
        } else {
          await this.$serviceExcecoes.remove(this.record.id);
        }
        this.record = {};
        this.load();
        this.deleteRecordDialog = false;
        this.$toast.add({
          severity: "success",
          summary: "Excluído com sucesso!",
          life: 3000,
        });
      } catch (err) {
        console.error(err);
        this.$toast.add({
          severity: "error",
          summary: "Erro ao excluir!",
          life: 3000,
        });
      }
    },
  },
};
</script>
